import React from 'react';
import Layout from '../components/layout/layout';

const NotFoundPage = () => (
  <Layout title="Page Introuvable">
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
